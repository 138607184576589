.iframe-container-driller {
  display: flex;
  height: 100%;
  width: 100%;
  // background-color: blue;
}

.iframe-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  // border: 1px solid white;
  // background: green;
}

.zoom-out {
  transform: scale(0.95); /* Ajuste o valor para o nível de zoom desejado */
  transform-origin: top left;
}

.pae {
  width: 100%;
  height: 100%;
}

.iframe-description {
  text-align: center;
  padding: 5px;
  background-color: #3D4D5A;
  margin-bottom: 5px;
  color: #ffffff;
  font-size: larger;
  font-weight: bold;
}

.iframe-content {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 177.78%; // 16:9 aspect ratio
  overflow: hidden;
}

.iframe-content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  // background-color: #264044;
}

.iframe-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: not-allowed;
  // background-color: #264044;
}