div>.poco-info{
    padding: 1rem 0;
    font-size: 1rem;
  }
.info-base .poco-info{
    label, span{
      padding: 7px
    }
    label{
      flex: 0.3 1;
      min-width: 40px;
      margin-right: 1.5rem;
    }
  }
.poco-info{
    padding: 0 1rem;
    text-align: left;
    label{
      font-weight: bold;
    }
    span, .pin-infos{
      font-weight: normal;
      text-align: left;
      flex: 1;
    }
  
  }