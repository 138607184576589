$cementingColor: #2ED1C8;
$casingColor: #145974;
$drillingColor: #702C92;

.plataforma{
  .imagem-plataforma, .div-legendas-top{
    max-height: 16rem;
  }
  .imagem-plataforma{
    display: flex;
    align-items: center;
    align-self: center;
    position: relative;
    .div-agua{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 45%;
    }
    img{
      max-width: 13rem;
      max-height: 100%;
      margin: 0 auto;
      margin-left: 5px;
      height: 250px;
    }
  }
  .div-graficos{
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    div{
      padding: 0 2.5%;
      border: 1px solid #fff;
    }
    .barraBroca{
      background-color: #fff;
      margin: 0 5px;
    }
  }
  .div-legendas {
    .div-legendas-top{
      flex: .55;
    }
    .logo-rzx {
      max-height: 40px;
      justify-content: center;
      img{
        width: 75%;
      }
    }
    .borda{
      padding: 0 .5rem;
      border-left: 1px solid white;
      border-bottom: 1px solid white;
      display: flex;
      flex: 1 1;
      align-items: flex-end;
      flex-direction: initial;
      text-align: left;
      position: relative;
      &.sem-borda{
        border-left: none;
      }
      &::before{
        content: '';
        display: block;
        border-radius: 5px;
        width: 5px;
        height: 5px;
        left: -3px;
        bottom: -3px;
        position: absolute;
        background-color: white;
      }
    }
  }
  .div-cor-legenda, .div-infos>div{
    border-radius: 30px;
    font-size: 10px;
    line-height: 10px;
    flex: 0.05;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    margin: .5rem;
    background-color: #7785a1;
    .circulo{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid #fff;
      position: relative;
      &.cementingColor{
        background-color: $cementingColor;
      }
      &.casingColor{
        background-color: $casingColor;
      }
      &.drillingColor{
        background-color: $drillingColor;
      }
    }
  }
  .div-infos{
    &>div{
      flex: 1;
      height: 3.5rem;
      display: flex;
      padding: 0 .5rem;
    }
    .infos-depth{
      justify-content: center;
      font-size: .9rem;
      text-align: left;
      span{
        font-size: 0.55rem;
      }
    }
  }
}