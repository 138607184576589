.lessonlearned-table{
    table{
        border-collapse: collapse;
        text-indent: initial;
        border-spacing: 1px;
    }
    .theader, .theader-texto-cinza{
        background-color: #0C5D70;
        font-weight: bold;

    }
    td{
        text-align: center;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }
    tr.bg-azul{
        margin:10px;
        background-color: #498FA1;
    }
}