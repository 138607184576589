.infos-alarme{
    letter-spacing: -1px;
    min-height: 11rem;
    .thead-texto-branco{
        text-align: left;
    }
    .thead-font{
        font-size: 16px
    }
    .cell-texto-cinza{
        color:#94A0AA;
        font-size: 12px;
        min-width: 50px;
    }
    .no-alarms{
        min-height: 6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .tbody-font{    
        font-size: 16px;
        text-align: left;
        padding-top: 0.5rem;
    }
    .column-bold-text {
        font-weight: bold;
    }
    .tbody-font .check-icon{
        color:green;
        font-size:1px;
    }
    .tbody-font .cancel-icon{
        color:#f29d38;
        font-size:1px;
    }
    .label{
        font-size: 16px;
    }
    .legenda{
        border-radius: 30px;
        font-size: 15px;
        line-height: 24px;
        flex: 0.05;
        align-items: center;
        align-content: center;
        justify-content: space-evenly;
        margin: .5rem;
        background-color: #7785a1;
    }
}