@import '../../styles/variables.scss';

.infos-tabelas{
    .tabela-theader::after{
        content: "";
        display: block;
        height: 1vh;
    }
    .theader, .theader-texto-cinza{
        background-color: #182230;
        font-weight: bold;
    }
    .theader{
        color: $color-white;
    }
    .theader-texto-cinza{
        color: #94A0AA;
    }
    .table-font{
        font-size: 0.7em;
    }
    .tabela thead tr th{
        padding: 0.5rem;
    }
    .tabela tbody::after{
      content: '';
      display: block;
      height: 3vh;
    }
    .tr-rotate{
        padding: 1.5rem .5rem !important;
        line-height: 10px;
        span{
            position: absolute;
            top: 5%;
            left: 25%;
        }
    }
    .tabela .rotate {
      vertical-align: middle;
      text-align: center;
        span {
        -ms-writing-mode: tb-rl;
        -webkit-writing-mode: vertical-rl;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        white-space: nowrap;
        }
        &.bg-dark{
            background-color: #182230;
        }
    }
    .text-destacado {
        td{
            font-weight: bold;
            color: $color-white;
        }
    }
    .tr-text-destacado{
        font-weight: bold;
        color: $color-white !important;
    }
    .bg-verde{
        background-color: #134F62 !important;
    }
    .border{
        border: 1px solid $color-black;
    }
    .destaque {
        td:last-child::after{
            content: '';
            display: block;
            background-color: $color-white;
            width: 5px;
            height: 5px;
            border-radius: 10px;
            position: absolute;
            right: 0;
            bottom: -2px;
        }
        td {
            position: relative;
            border-bottom: 1px solid $color-white;
        }
    }
    .section-label::after{
        content:'';
        height: 1vh;
    }
    .section-label td{
        height: 50px;
    }

    
}


