@import '../../styles/variables.scss';



.selecionar-plataforma-page {
  margin: 0;
  color: #fff;
  max-width: 100vw !important;

  .header {
    padding-top: 2.5rem;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-style: solid;
    border-bottom-width: 2px;
    border-color: #fff;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .rigs {
    display: inline-flex;
    grid-gap: 32px;
    grid-column: 5;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-margin-start: 5%;
    margin-inline-start: 5%;
    -webkit-margin-end: 5%;
    margin-inline-end: 5%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .chamfer {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none"><path d="M0.660118 19.6333L0.5 19.7817V20V492V492.5H1H321H321.5V492V1V0.5H321H21.5H21.3039L21.1601 0.633285L0.660118 19.6333Z" fill="white" fill-opacity="0.1" stroke="white" /></svg>');
    padding-top: 5px;
    width: 322px;
    height: 495px;
    flex-shrink: 0;


    .content {
      display: grid;
      grid-column: 2;
      margin-top: 32px;
      margin-inline-start: 50px;
      margin-inline-end: 50px;

      .subheader {
        grid-column: span 2;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 20px 0 10px 0;
        text-align: center;
      }
    }

    .c1 {
      grid-column-start: 1;
      white-space: nowrap;
    }

    .c2 {
      grid-column-start: 2;
      text-align: right;
      white-space: nowrap;
    }
  }

}