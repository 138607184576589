.top-drive{
    background-color: #000;
    .mark{
      position: absolute;
    }
    .mark-mensagem{
      width: 100%;
      text-align: end;
      margin-left: -5%;
      margin-right: 5%;
      position: relative;
      -webkit-transition: all 0.7s ease-out;
        -moz-transition: all 0.7s ease-out;
          -ms-transition: all 0.7s ease-out;
          -o-transition: all 0.7s ease-out;
              transition: all 0.7s ease-out;
      &::after{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
      }
      font-size: 0.8rem;
    }
    .mark.error .mark-mensagem{
        color: #FF0000;
      &::after{
        border-bottom: 1px solid red;
      }
    }
    .span-mark-message{
      text-shadow: 2px 1px rgb(0, 0, 0), -2px -1px rgb(0, 0, 0);
      position: absolute;
      margin: 0;
      margin-bottom: 0px;
      bottom: 3px;
      line-height: 12px;
      margin-bottom: .5rem;
      right: 0rem;
      word-wrap: inherit;
      width: 3rem;
      font-size: 12px;
      font-weight: bold;
    }
    
    .mark.warning .mark-mensagem{
      color: yellow;
      &::after{        
        border-bottom: 1px solid yellow
      }
     
    }
    .mark.success  .mark-mensagem{
      color: green;
      
      &::after{
        border-bottom: 1px solid green;
        
      }
    }
    .icone-posicao{
      position: absolute;
      margin-top: 5px !important;
      left: -3%;
      top: -50%;
    }
    .borda-direita{
      border-right: 1px solid white;
      border-bottom: 1px solid white;
      width: 10px;
    }
    .borda-esquerda{
      border-left: 1px solid white;
      border-bottom: 1px solid white;
      width: 10px;
      position: relative;
    }
    .topdrive-index-cell{
      flex: 0.07 1;
      .topdrive-indice{
        position: absolute;
        left: 10%;
        bottom: -35%;
        width: 45%;
      }
    }
    
}