@import '../../styles/variables.scss';

.mailing-page{
    margin-top: 8px;
    padding: 1px;
    display: flex;
    max-width: 1200px;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    background-color: $white-alpha-800;
    padding: 10px;
}