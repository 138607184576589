@import "../../../styles/variables.scss";

.tabelas-grafico-operacao{
  tbody::after{
    content: '';
    display: block;
    height: 1vh;
  }
  .th-span{
    padding: 0 .4rem;
    span{
      padding: 10%;
      height: 1.5rem;
      display: inline-block;
      vertical-align: middle;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: #34385C;
    }
  }
  .rotate{
    background-color: transparent !important;
    min-width: 11px;
    max-width: 11px;
    padding: 0;
  }
  .rotate div{
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - 14px);
    width: 15px;
    background-color: $main-bg-color;
    span{
      left: 30%;
    }

  }
  .rotate::after{
    content: '';
    display: block;

    position: absolute;
    right: 0;
    bottom: -1px;

    width: 0; 
    height: 0; 
    border-bottom: 15px solid transparent;    
    border-right: 15px solid $main-bg-color;
  }
  .div-grafico-sem-dados{
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    font-size: .8rem;
  }
  .div-vazia{
    height: 130px;
  }
}

